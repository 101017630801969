.modal-container {
    position: fixed;
    width: 100vw;
    height: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.modal-item {
    width: 90%;
    max-width: 600px;
    max-height: 700px;
    border: 5px;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    border-radius: 10px;
    overflow: auto;
}

.modal-head{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}

.modal-close:hover{
    color: hsl(0, 70%, 70%);
}