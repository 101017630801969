.survey-item {
    margin-top: 20px;
}

.survey-item h2 {
    font-weight: 400;
}

.survey-item p {
    margin-top: 10px;
    font-size: 18px;
    max-height: 100px;
    line-height: 25px;
    overflow-y: auto;
    color: hsl(210, 20%, 25%);
}