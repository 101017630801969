html:focus-within {
    scroll-behavior: smooth;
}

body {
    background: linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%);
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    width: 100%;
    min-height: 900px;
    vertical-align: center;
    text-align: center;
    margin-top: 50px;
}
.jumbotron {
    padding-top: 100px;
    text-align: center;
}

h1 {
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    line-height: 1.5;
    text-shadow: 2px 2px #F5F5F5;
}
h3 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 1em !important;
    line-height: 1.5 !important;
    text-shadow: 2px 2px #F5F5F5;
}
#root {
    padding-top: 50px;
    vertical-align: center;
    width: 750px;
    min-height: 600px;
    background-color: white;
    border-radius: 15px !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 10px auto auto;
}
button {
    padding: 12px 12px;
    border: 1px solid #0d6efd9e !important;
    border-radius: 8px!important;
    background-color: #0d6efd9e !important;
    color: white !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
    height: 40px;
    cursor: pointer;
}