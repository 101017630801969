.survey-manager > header {
    font-family: "Open Sans", sans-serif;
    font-size: 2em;
    line-height: 1.5;
    text-shadow: 2px 2px #F5F5F5;
}

.survey-manager-container {
    width: 95%;
    margin: 50px auto 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.survey-manager-container > button {
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 18px;
    width: 150px;
    text-align: center;
    margin: auto;
    cursor: pointer;
}

.survey-manager-container > button:hover{
    background-color: hsl(210, 12%, 25%);
}

.survey-manager-container > button:active{
    background-color: hsl(210, 12%, 15%);
}

.survey-manager-surveys{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}