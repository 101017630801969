.add-survey {
    display: flex;
    flex-direction: column;
    gap: 55px;
    margin-top: 10px;
    z-index: 100;
}

.add-survey input{
    height: 40px;
    width: 100%;
    border: unset;
    margin-bottom: 10px;
    font-size: 18px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border-bottom: 1.5px solid hsl(210, 10%, 75%);
    text-transform: none !important;
    padding-left: 5px;
}

.add-survey input:focus{
    outline: none;
}

.add-survey button {
    height: 40px;
    border: none;
    color: white;
    background-color: hsl(210, 12%, 15%);
}

.add-survey textarea {
    height: 150px;
    border-color: hsl(210, 10%, 75%);
    padding: 5px;
    font-size: 18px;
}

.add-survey textarea:focus {
    outline: none;
}

.add-survey button:hover {
    background-color: hsl(210, 12%, 25%);
}

.add-survey button:active {
    background-color: hsl(210, 12%, 15%);
}