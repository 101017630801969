.header-text {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5em;
    line-height: 1.5;
    text-shadow: 1px 1px #F5F5F5;
    margin-top: 60px;
}
.header {
    margin-top: -20px;
    padding-right: 15px;
    text-align: end;
}
.header div {
    text-align: center;
}
.logo {
    background-image: url("./survlee-logo.png");
    background-repeat: no-repeat;
    background-size: 150px;
    width: 150px;
    height: 100px;
    margin-left: 25px;
    margin-top: -10px;
    position: absolute;
}