.survey {
    width: 100%;
    margin-top: 10px;
    padding: 15px 10px;
    max-width: 700px;
    margin-left: 10px;
    display: flex;
    box-sizing: border-box;
    background-image: url("./survey-background.svg");
    background-repeat: no-repeat;
    background-size: 700px;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.survey-border-color {
    border-color: green;
    box-shadow: 0 6px 18px -9px hsl(210, 15%, 65%);
}

.survey-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    line-height: 1.5;
    text-shadow: 1px 1px #F5F5F5;
}

.survey-body h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.5em;
    line-height: 1.5;
    text-shadow: 1px 1px #F5F5F5;
}

.survey-body p {
    margin: 10px 0 0 0;
    overflow: hidden;
    display: -webkit-box;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.survey-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    align-items: center;

}

.survey-buttons > button {
    background-color: hsl(210, 70%, 60%);
    padding: 5px 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: white;
}

.survey-buttons > button:hover {
    background-color: hsl(210, 70%, 50%);
}

.survey-buttons > button:active {
    background-color: hsl(210, 70%, 60%);
}

.survey-delete-edit button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: hsl(210, 10%, 55%);
}

.survey-edit-button:hover {
    color: hsl(210, 20%, 25%);
}

.survey-edit-button:active {
    color: hsl(210, 10%, 55%);
}

.survey-delete-edit .survey-delete-button {
    color: hsl(0, 80%, 80%);
}

.survey-delete-button:hover {
    color: hsl(0, 70%, 70%);
}

.survey-delete-button:active {
    color: hsl(0, 80%, 80%);
}

.survey-question p {
    text-align: left;
}

.survey-question-delete {
    background-image: url("./trash-icon.svg");
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: -30px;
    margin-top: -75px;
    position: relative;
    cursor: pointer;
}
.survey-elements {
    text-align: center;
    padding-bottom: 10px;
}

.survey-element {
    text-align: left;
    margin-left: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
}
.survey-element button {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
}
.survey-question-button .active {
    background-color: gray !important;
}
.survey-save {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 20px;
}
.copy-icon {
    background-image: url("./copy-icon.svg");
    width: 25px;
    height: 25px;
    margin-left: -50px;
    margin-top: -20px
}